<template>
  <!--  (' + `${form.id.length}` + ')  -->
  <a-modal
    :visible="visible"
    :title="'编辑联系人'"
    :mask-closable="false"
    :width="600"
    @ok="handleOk"
    centered
    @cancel="handleCancel"
    forceRender
    :destroyOnClose="true"
  >
    <!--   :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"  -->
    <a-form layout="vertical">
      <a-form-item label="选择字段">
        <a-select v-model:value="form.field" placeholder="请选择">
          <a-select-option
            v-for="item in fieldList"
            :key="item.value"
            :value="item.value"
            @click="handleSelect(item)"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="输入修改字段内容" v-if="form.field === 'company'">
        <a-input
          v-model:value="form.company"
          placeholder="请输入修改字段内容"
        />
      </a-form-item>
      <a-form-item label="输入修改字段内容" v-else>
        <a-input
          v-model:value="form.position"
          placeholder="请输入修改字段内容"
        />
      </a-form-item>
    </a-form>
    <template v-slot:footer>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleOk" :loading="loading"
        >确认</a-button
      >
    </template>
  </a-modal>
</template>
<script>
import { reactive, toRaw, toRefs, watch } from 'vue';
import { Form, message, Select } from 'ant-design-vue';
import contactAll from '@/api/contactAll';
import { useStore } from 'vuex';

// import { validMobile, validEmail } from '@/utils/validation';
// import {
//   PlusOutlined,
// } from '@ant-design/icons-vue';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    // PlusOutlined,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const store = useStore();
    const userId = store.state.account.user.id;
    // const required = { required: true, message: '不能为空' };
    const form = reactive({
      id: [],
      field: 'company',
      fieldContents: '',
      userId: userId,
      selectAll: null,
      pageDTO: {},
    });

    const state = reactive({
      fieldList: [
        { value: 'company', label: '公司' },
        { value: 'position', label: '职位' },
      ],
      count: 0,
      loading: false,
    });

    // resetFields
    const { validateInfos, validate } = Form.useForm(form, {
      // fieldContents: [required],
    });

    watch(
      () => props.init,
      value => {
        console.log(value);
        Object.assign(form.id, value.id);
        form.selectAll = value.selectAll
        form.pageDTO = value.pageDTO
      }
    );

    const handleCancel = () => {
      context.emit('update:visible', false);
      // 表单重置
      // resetFields();
      form.company = '';
      form.position = '';
    };

    return {
      ...toRefs(state),
      form,
      validateInfos,
      handleCancel,
      handleSelect(item) {
        form[item.value] = '';
      },
      // 分类列表保存方法调用接口的方法和事件
      handleOk: () => {
        if (form.field === 'company') {
          if (!form.company) {
            return message.error('请输入修改字段内容！');
          }
        } else {
          if (!form.position) {
            return message.error('请输入修改字段内容！');
          }
        }

        validate().then(() => {
          state.loading = true;
          contactAll.updateUserInfo('', toRaw(form)).then(res => {
            if (res) {
              form.company = '';
              form.position = '';
              state.loading = false;
              message.success('操作成功');
              context.emit('fnOk', false);
              // resetFields();
            }
          }).catch(() => {
            message.error('网络异常，请联系技术人员处理')
            state.loading = false;
          });
        });
      },
    };
  },
};
</script>
<style lang="less" scoped>
.live-group {
  padding: 0 12px 15px 10px;
  display: flex;
  align-items: center;
  .group-input {
    flex: 1;
  }
}

.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
}
.live-group .group-input[data-v-61219ec2] {
  flex: 1;
  padding-bottom: 15px;
}
.flex {
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
}
span.anticon.anticon-minus-circle {
  color: #5979f8 !important;
}
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #313d5f;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
</style>
